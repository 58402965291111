import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

const colorDefaultChart = ['#FBA63C', '#08A0F7']

export const chartOptions = {
  series: [],
  resetSeries: {
    shouldUpdateChart: true,
  },
  colors: ['#FBA63C', '#08A0F7'],
  chart: {
    type: 'area',
    stacked: false,
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  stroke: {
    curve: 'smooth',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.75,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  yaxis: {
    forceNiceScale: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      formatter: val => val,
    },
  },
  xaxis: {
    type: 'category',
    categories: [],
    tickAmount: 14,
  },
  noData: {
    text: 'Halo admin, saat ini sedang tidak ada data',
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
  },
  tooltip: {
    shared: false,
    custom({
      series, dataPointIndex, w,
    }) {
      const member = w.globals.initialSeries[0]
      const comission = w.globals.initialSeries[1]
      const seriesYear = DAY_MONTH_YEAR(w.config.xaxis.min[dataPointIndex])
      const labelRegister = series[0].length !== 0
        ? `${'<small>'
              + 'Hari ini '
              + '</small>'}<span class="text-black text-lg ml-[5px]">${
          member.data[dataPointIndex]
        } Register</span>`
              + '<br>'
        : ''
      const labelKomisi = series[1].length !== 0
        ? `<div class="font-semibold text-xl text-black">${IDR(
          comission.data[dataPointIndex] * 1000,
        )
        }</div>`
                + '<br>'
        : ''
      const result = `<div class="px-1 py-75" style="box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);">
              ${labelRegister}
              ${labelKomisi}
              <small>
              ${seriesYear}
              </small>
              </div>`
      return result
    },
  },
}

export const chartOptionsMember = {
  colors: colorDefaultChart,
  chart: {
    type: 'area',
    stacked: false,
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  stroke: {
    curve: 'smooth',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.75,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  yaxis: {
    forceNiceScale: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      formatter: val => val,
    },
  },
  xaxis: {
    type: 'category',
    categories: [],
    tickAmount: 14,
  },
  noData: {
    text: 'Halo admin, saat ini sedang tidak ada data',
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
  },
  tooltip: {
    shared: false,
    custom({ series, dataPointIndex, w }) {
      const register = w.globals.initialSeries[0]
      const comission = w.globals.initialSeries[1]
      const seriesYear = DAY_MONTH_YEAR(w.config.xaxis.min[dataPointIndex])
      const labelRegister = series[0].length !== 0
        ? `${'<small>'
              + 'Hari ini '
              + '</small>'}<span class="text-black text-lg ml-[5px]">${
          register.data[dataPointIndex]
        } Register</span>`
              + '<br>'
        : ''
      const labelKomisi = series[1].length !== 0
        ? `<div class="font-semibold text-xl text-black">${IDR(
          comission.data[dataPointIndex] * 1000,
        )
        }</div>`
                + '<br>'
        : ''
      const result = `<div class="px-1 py-75" style="box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);">
              ${labelRegister}
              ${labelKomisi}
              <small>
              ${seriesYear}
              </small>
              </div>`
      return result
    },
  },
}

export const columnAffiliator = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'affiliator_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'phone_no',
    label: 'Whatsapp',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'join_date',
    label: 'Tanggal Bergabung',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
    sortable: true,
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'total_member',
    label: 'Total Member',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
    sortable: true,
  },
  {
    key: 'total_commission',
    label: 'Total Komisi',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
    sortable: true,
    formatter: value => IDR(value),
  },
  {
    key: 'action',
    label: 'Detail',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
]

export const columnMemberAffiliator = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'member_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'warehouse_address',
    label: 'Gudang',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'bank_info',
    label: 'No Rekening',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'join_date',
    label: 'Tanggal Bergabung',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'total_shipping',
    label: 'Total Ongkir',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value),
  },
  {
    key: 'total_commission',
    label: 'Total Komisi',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value),
  },
]

export const columnMemberBooster = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'member_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'join_date',
    label: 'Tanggal Register',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'package_name',
    label: 'Status',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'package_price',
    label: 'Harga Paket',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value),
  },
  {
    key: 'total_commission',
    label: 'Komisi',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value),
  },
]
export const columnMemberKompack = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'member_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'warehouse_address',
    label: 'Gudang',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'bank_info',
    label: 'No Rekening',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'join_date',
    label: 'Tanggal Bergabung',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'total_fulfillment',
    label: 'Total Biaya Fulfillment',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value),
  },
  {
    key: 'total_commission',
    label: 'Total Komisi',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value),
  },
]
export const columnMemberKomcards = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
  },
  {
    key: 'member_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'bank_info',
    label: 'No Rekening',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'join_date',
    label: 'Tanggal Bergabung',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'total_transaction_price',
    label: 'Total Biaya Transaksi',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value),
  },
  {
    key: 'total_commission',
    label: 'Total Komisi',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value),
  },
]

export const serviceName = [
  {
    name: 'semua',
  },
  {
    name: 'komship',
    image_url:
      'https://storage.googleapis.com/komerce/assets/svg/komship-logo-yellow.svg',
  },
  {
    name: 'boostr',
    image_url: 'https://storage.googleapis.com/komerce/assets/logo/booster.svg',
  },
  {
    name: 'kompack',
    image_url: 'https://storage.googleapis.com/komerce/assets/logo/Kompack-logo.svg',
  },
]
