<template>
  <BCard
    :class="borderClass"
    body-class="p-1 flex flex-col justify-between"
  >
    <div class="flex items-center mb-[5px]">
      <div class="text-black text-lg">
        {{ title }}
      </div>
      <img
        v-if="icon"
        v-b-tooltip.hover.top="hoverText"
        src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
        alt="Komerce"
        variant="outline-primary"
        class="ml-[5px] cursor-pointer"
      >
    </div>
    <BSpinner
      v-if="loading"
      small
      variant="primary"
    />
    <div
      v-else
      class="text-black text-2xl font-semibold"
    >
      {{ count }}
    </div>
  </BCard>
</template>

<script>
import { IDRWithoutLbl, IDR } from '@/libs/currency'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    count: {
      type: String,
      default: '',
    },
    borderClass: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    hoverText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      IDRWithoutLbl,
      IDR,
    }
  },
}
</script>

<style>
.card {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  min-height: 120px;
}
</style>
